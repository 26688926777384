@use "modules/colors.module";

.input-line {
  background-color: transparent !important;
  border: none;
  border-bottom: 2px solid black;
  outline: 0;
  border-radius: 0 !important;
  color: black !important;
  font-size: 1.2em;
  width: 100%;

  &:hover, &:focus, &:active {
    border-bottom: 2px solid black;
  }
}

.focus-border {
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 0;
  height: 2px;
  background-color: colors.$storychest-color;
  transition: 0.4s;
  -webkit-transition: 0.4s;
}

.input-line:focus~.focus-border {
  width: 100%;
  transition: 0.4s;
  left: 0;
}

.input-line::-webkit-input-placeholder {
  color: rgba(0,0,0,.75) !important;
}

.input-line::-moz-placeholder {
  color: rgba(0,0,0,.75) !important;
}

.input-line:-moz-placeholder {
  color: rgba(0,0,0,.75) !important;
}

.input-line:-ms-input-placeholder {
  color: rgba(0,0,0,.75) !important;
}

.input-line::placeholder {
  color: rgba(0,0,0,.75) !important;
}

.input-line:-webkit-autofill, .input-line:-webkit-autofill:hover, .input-line:-webkit-autofill:focus,
.input-line:-webkit-autofill:active {
 -webkit-box-shadow: 0 0 0 40px white inset !important;
}