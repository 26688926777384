.clear-both {
  clear: both;
}

.text-black {
  color: black;
}

.storychest-box-shadow {
  box-shadow: rgb(50 50 93 / 25%) 0 13px 27px -5px, rgb(0 0 0 / 30%) 0px 8px 16px -8px;
}

.storychest-box-shadow-light {
  box-shadow: rgb(50 50 93 / 5%) 0 13px 27px -5px, rgb(0 0 0 / 10%) 0px 8px 16px -8px;
}