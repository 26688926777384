@use "../modules/colors.module";
/** `.inputLine` is meant to be combined with `.input-line` from `form.scss` */

.inputsGroup {
  margin-bottom: .75rem;
}

.featureCharacterImage, .specificCharacterImage {
  width: 75px;
  height: 75px;
  object-fit: cover;
  border-radius: 50%;
  border: 1px solid #ededed;
}

.shareStoryWithButton {
  width: 100%;
  color: colors.$storychest-color;
  border: 1px solid colors.$storychest-color;
  background-color: transparent;

  &:hover, &:focus, &:active {
    box-shadow: none;
  }
}

.shareStoryWithButton.active {
  background-color: colors.$storychest-color;
  color: white;
}

.accordionCard {
  border: 1px solid #DC3151 !important;
  border-radius: 0.25rem !important;
  text-align: center;
  margin-bottom: .75rem;
}

.accordionCardHeader {
  background-color: transparent;
  padding: 0;
}