@use "../modules/colors.module";

.charactersContainer {
  @media only screen and (min-width: 1200px) {
    max-width: 1400px;
  }
}

.characterFigure {
  width: 250px;
  height: 250px;

  .characterImage {
    width: 160px;
    height: 160px;
  }

  .storychestUserLogo {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    top: 4px;
    right: 4px;
    background-color: colors.$storychest-color;

    > span {
      font-size: 1.4em;
    }
  }
}

.charactersSectionHeadline {

  > h2 {
    font-size: 2.2em;
  }

  > hr {
    width: 300px;
    border-width: 2px;
    border-color: colors.$storychest-color;
  }
}