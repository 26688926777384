@use "../modules/colors.module";
@use "../modules/media";

$characterItemWidth: 330px;

.charactersModalContent {
  width: $characterItemWidth + 150px;
}

.modalHeader {
  border-bottom: none;
  height: 70px;
}

.modalBody {
  height: 500px;
  overflow-y: scroll;
  padding: 1rem 2rem 0 2rem;
}

.modalInputBottomLine {
  background-color: transparent;
  border: 0;
  outline: 0;
  border-bottom: 2px solid black;
  border-radius: 0 !important;
  color: black !important;

  &:hover, &:active, &:focus {
    background-color: transparent !important;
    border-bottom: 2px solid black !important;
    box-shadow: none !important;
  }

  &::placeholder {
    color: black;
  }
}

.charactersContainer {
  padding: 0 0 1.5rem 0;
}

.characterItemContainer {
  background-color: white;
  box-shadow: rgba(100, 100, 111, 0.2) 0 7px 29px 0;
  width: $characterItemWidth;
  padding: .5rem;
  margin-top: 1.5rem;
  cursor: pointer;
  text-align: left;
  -webkit-transition: background-color .3s linear;
  -moz-transition: background-color .3s linear;
  -o-transition: background-color .3s linear;
  transition: background-color .3s linear;

  .characterItem {
    padding: 0;

    > img {
      width: 70px;
      height: 70px;
      object-fit: cover;
      border-radius: 50%;
    }

    .characterName {
      margin-left: .5rem;
    }
  }

  &:hover {
    border-right: 3px solid colors.$storychest-color;
  }

  &.active {
    background-color: colors.$storychest-color;

    .characterName {
      color: white;
    }
  }
}

@media only screen and (max-width: media.$mobile-breakpoint) {
  .characterItemContainer {
    width: 100% !important;
  }
}