@use "../modules/colors.module";
@use "../modules/sizes";
@use "../modules/media";

#storiesWrapper {
  min-height: 100vh;
  padding-left: 0;
  padding-right: 0;
  background: white url("../../static/smudges2.png") no-repeat fixed 50% 50%;
  -webkit-transition: all 0.6s;
  -o-transition: all 0.6s;
  transition: all 0.6s ;

  &.active {
    padding-left: sizes.$sidebar-width;
  }

  .iconMagnifier, .iconCross {
    color: colors.$storychest-color;
  }
}

#storiesContainer {
  // opacity: 0;
  -webkit-transition: all 0.6s ease-in-out;
  -o-transition: all 0.6s ease-in-out;
  transition: all 0.6s ease-in-out;
  transition-delay: .5s;

  &.active {
    opacity: 1;
  }
}

.sidebarCollapseButtonContainer {
  position: fixed;
  z-index: 1;
  margin-top: 60px;
  padding-top: 7px;
  -webkit-transition: all 0.6s;
  -o-transition: all 0.6s;
  transition: all 0.6s;

  > .sidebarCollapseButton > .fa-search, .fa-times {
    font-size: 1.4em;
    color: colors.$storychest-color;
  }
}

.fa-calendar-day {
  color: colors.$sidebar-color;
}

.iconRightArrowWrapper {
  transform: rotate(180deg);
  padding-top: .2rem;
  padding-left: .25rem;

  .iconRightArrow {
    color: black !important;
  }
}

@media only screen and (max-width: media.$stories-sidebar-change-breakpoint) {
  #stories-wrapper {
    &.active {
      padding-left: 0;

      .sidebarCollapseButtonContainer {
        margin-left: 255px;
      }
    }
  }
}