/** react-image-gallery styling override */

.image-gallery-bullets-container {
  line-height: 15px;
}

.image-gallery > .fullscreen {
  .image-gallery-slides {
    height: 100vh;

    .image-gallery-slide {
      width: 100%;
      height: 100%;

      > .image-gallery-image {
        width: 100%;
        max-height: 100% !important;
        height: 100%;
      }
    }
  }
}

.image-gallery-content .image-gallery-slide .image-gallery-image {
  max-height: calc(100vh - 220px) !important;
}