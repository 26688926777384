@use "../modules/colors.module";

$grapevineItemWidth: 1164.8px;
$grapevineItemHeight: 436.8px;  // 480px (default cover image's height) * 0,91

.grapevineContainer {
  background: white url("../../static/smudges2.png") no-repeat fixed 50% 50%;
}

.grapevineItem {
  width: $grapevineItemWidth;
  height: $grapevineItemHeight;
  margin: 0 0 5rem 0;
  box-shadow: colors.$box-shadow;
  background-color: white;
  display: flex;
  flex-wrap: nowrap;
  // background: rgba(colors.$storychest-color-R, colors.$storychest-color-G, colors.$storychest-color-B, .05);

  .grapevineImage {
    width: 582.4px;  // 640px (default cover image's width) * 0,91
    height: 100%;

    > img {
      height: $grapevineItemHeight;
    }
  }

  .grapevineContentWrapper {
    width: 582.4px;
    height: 100%;
    overflow-y: auto;

    .grapevineContentNavbar {
      position: sticky;
      display: flex;
      justify-content: flex-end;
      top: 1.2rem;
      margin-right: 1rem;
      min-height: 40px;
      max-height: 40px;

      .likesWrapper {
        background-color: white;
        box-shadow: #ededed 0 0 8px 2px;
        padding: .6rem 1.3rem;
        border-radius: 5px 10px 15px 20px;

        .likes {
          font-size: 1.2em;
        }
      }
    }

    .grapevineMainContent {
      margin-top: -1rem;

      .authorImage {
        width: 65px;
        height: 65px;
        border: 1px solid #ededed;
      }

      .grapevineDescription {
        font-size: 1em;
      }
    }
  }
}

@media only screen and (max-width: 1260px) {
  .grapevineItem {
    width: auto;
    height: 100%;
    flex-direction: column;

    .grapevineContentWrapper {
      height: 450px;
    }
  }
}

@media only screen and (max-width: 620px) {
  .grapevineContainer {
    padding: 0;
  }

  .grapevineItem {
    width: 100%;

    .grapevineImage {
      width: 100%;

      > img {
        height: $grapevineItemHeight;
      }
    }

    .grapevineContentWrapper {
      width: 100%;
      height: 360px;

      .grapevineContentNavbar {

        .likesWrapper {
          padding: .4rem .8rem;
        }
      }

      .grapevineMainContent {

        .authorImage {
          width: 45px;
          height: 45px;
        }
      }
    }
  }
}