@use "../modules/colors.module";
@use "../modules/sizes";
@use "../modules/media";
@use "labels.module";
@use "characters.module";

#sidebar {
  width: sizes.$sidebar-width;
  height: 100vh;
  min-height: 100%;
  overflow: auto;
  margin-left: - sizes.$sidebar-width;
  position: fixed;
  background-color: colors.$sidebar-color;
  z-index: 1;
  padding-top: 74px;
  -webkit-transition: all 0.6s;
  -o-transition: all 0.6s;
  transition: all 0.6s;

  &.active {
    margin-left: 0;
  }

  label {
    font-size: 1.2em;
    color: colors.$sidebar-text-color;
  }

  #featuringSection {
    height: 110px;
    border-bottom: 2px solid colors.$sidebar-text-color;;
  }
}

.sidebarInput {
  background-color: transparent;
  border: 0;
  outline: 0;
  border-bottom: 2px solid colors.$sidebar-text-color;
  border-radius: 0 !important;
  color: colors.$sidebar-text-color !important;

  &:hover, &:active, &:focus {
    background-color: transparent !important;
    border-bottom: 2px solid white !important;
    box-shadow: none !important;
  }

  &::placeholder {
    color: colors.$sidebar-text-color;
  }
}

::-webkit-calendar-picker-indicator {
  @if colors.$sidebar-text-color == white {
    filter: invert(1);
  }
}

.sidebarThumbnail {
  position: absolute;
  width: 55px;
  height: 55px;
  border-radius: 50%;
}

#iconLabelsCross {
  color: black;
  font-size: .8em;
  align-self: center;
}