@use "./styles/modules/colors.module";

#forgotPasswordLink {
  color: colors.$storychest-brown;
  font-weight: bold;
  &:hover {
    color: colors.$storychest-brown;
  }
}

#appStoreBgImage {
  position: relative;
  cursor: pointer;
  transition: all .3s;
  transform-origin: 50% 50%;

  &:hover, &:active, &:focus {
    transform: scale(1.1);
  }
}