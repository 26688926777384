@use "modules/media";
@use "modules/sizes";

.footer {
  width: 100%;
  height: sizes.$footer-height;
  background-color: #353535;
  z-index: 2;
  display: flex;
  align-items: center;

  p, a {
    color: white;
    font-size: 14px;
  }

  #footerLeft {
    text-align: center;
  }

  #footerRight {
    text-align: center;
  }
}

@media only screen and (max-width: media.$mobile-breakpoint) {
  .footer {
    height: calc(#{sizes.$footer-height} * 1.1);
    min-height: calc(#{sizes.$footer-height} * 1.1);
  }
}