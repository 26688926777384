@use "../modules/colors.module";

#charactersPlusIcon {
  color: colors.$sidebar-text-color;
  font-size: 1.5em;
  cursor: pointer;
  padding-left: .75rem;
  padding-right: 1rem;
  float: left;
}
