@use "./src/styles/modules/sizes";

$login-box-width: 460px;
$login-box-height: 630px;

#loginBox {
  width: $login-box-width;
  min-height: $login-box-height;
  background-color: rgba(255, 255, 255, 0.45);
}

@media only screen and (max-height: calc(#{sizes.$navbar-height} * 3 + #{$login-box-height} + #{sizes.$footer-height})) {
  .loginContentWrapper {
    height: calc(#{sizes.$navbar-height} * 2 + #{$login-box-height} + #{sizes.$navbar-height});
  }
}