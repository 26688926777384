$default-text-color: black;
$storychest-color: #DC3151;
$storychest-orange: #ff8e00;
$storychest-brown: #462f2f;
$storychest-gray: #424242;
$storychest-light-color: rgba(#DC3151, .75);
$storychest-darker-color: #c12c47;
$storychest-color-R: 220;
$storychest-color-G: 49;
$storychest-color-B: 81;
$light-gray: #ededed;
$sidebar-color: #f7bbc0;  // #ffa947;  rgba(250, 215, 218, .5)
$sidebar-text-color: black;
$story-item-date-color: $storychest-color;
$info-color: black;
$success-color: #199333;
$warning-color: #ffd642;
$error-color: $storychest-color;
$box-shadow: rgb(50 50 93 / 25%) 0px 13px 27px -5px, rgb(0 0 0 / 30%) 0px 8px 16px -8px;
$box-shadow-storychest-color: rgba(220, 49, 81, .75) 1px 1px 27px;

:export {
  storychestColor: $storychest-color;
}