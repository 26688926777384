@use "../modules/colors.module";

#labelsPlusIcon {
  color: colors.$sidebar-text-color;
  font-size: 1.5em;
  cursor: pointer;
  padding-left: .75rem;
  padding-right: 1rem;
  float: left;
}

.labelItem {
  background-color: transparent;
  border: 2px solid colors.$storychest-color;
  border-radius: 15px;
  float: left;
  margin: .25rem;

  p {
    display: inline-block;
    font-size: 1.1em;
    color: colors.$sidebar-text-color;;
    margin: 0;
    padding: .15em 1em;
  }
}