@use "modules/colors.module";

.alerts {
  width: 450px;
  height: 300px;
}

.alertImage {
  min-width: 60px;
  min-height: 60px;
  width: 60px;
  height: 60px;
}

.alertSentByName {
  color: colors.$storychest-color;
  font-weight: bold;
}

.alertText {
  white-space: normal;
}