@use "modules/sizes";
@use "modules/colors.module";

.navLogout {
  background-color: rgba(0, 0, 0, .3) !important;
}

.iconBook, .iconGrapevine, .iconUsers {
  color: white !important;
}

.navigationIconRounded {
  border: 1px solid #ededed;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, .1);
  margin-right: .15rem;

  .iconBell {
    font-size: 1.3em;
  }

  .iconPlay {
    font-size: .9em;
  }
}

.navbar {
  padding: 0;
  background-color: #DC3151;
  height: 64px;
  z-index: 10;

  #navbarContainer {
    height: 100%;

    #leftSide {
      position: absolute;
    }

    #rightSide {
      position: absolute;
      right: 0;
    }

    #navbarResponsive {
      height: 100%;
      padding-left: 0;
      -webkit-transition: all 0.8s;
      -o-transition: all 0.8s;
      transition: all 0.8s;

      &.sidebarActive {
        padding-left: sizes.$sidebar-width;
      }

      .navRight {
        .profileItem {
          margin-right: 1rem;
        }

        .navItem, .navbarProfileLink {
          align-self: center;
        }

        .navbarProfileLink {
          &:hover, &:focus, &:active {
            text-decoration: none;
          }
        }
      }

      .navMiddle {
        height: 100%;
        margin: 0 auto;

        .navItem {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          height: 100%;
          width: 130px;

          > .navLink {
            width: 100%;
            height: 100%;
            text-align: center;
            color: white !important;
            font-weight: 300;
            font-size: 16px;

            &.active {
              background-color: colors.$storychest-darker-color;
            }

            &:hover, &:focus, &:active {
              background-color: colors.$storychest-darker-color;
            }

            > span {
              color: white;
            }

            > p {
              padding: 0;
              margin: 0;
              text-align: center;
            }
          }
        }
      }
    }
  }
}

.btnNavbar {
  color: white;

  &:hover, &:focus, &:active {
    color: white;
  }
}

.navbarMenuButton {
  position: absolute;
  right: 0;
  padding-right: 2rem;
  font-size: 1.5em !important;
  cursor: pointer;
}

.menuOverlay {
  position: fixed;
  z-index: 9;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.9);
  padding-top: 64px;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: none;

  .linkWrapper {
    padding: 1rem;
  }

  .wrapperIcon span {
    font-size: 1.8em;
  }

  a {
    color: #fff;
    font-size: 2em;
    text-decoration: none;
    line-height: 75px;
  }
}

@media only screen and (max-width: 1200px) {
  #navbarContainer {
    max-width: 100%;
  }
}

@media only screen and (max-width: 1150px) {
  #navbarContainer {
    #navbarResponsive {
      &.sidebarActive {
        padding-left: 0 !important;
      }
    }
  }
}

@media only screen and (min-width: 810px) {
  .navbarExpandCustom {
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;

    .navbarNav {
      flex-direction: row;

      .navLink {
        padding-right: .5rem;
        padding-left: .5rem;
      }
    }

    .navbarCollapse {
      display: flex !important;
    }

    .navbarToggler {
      display: none;
    }
  }

  .navbar > .container {
    flex-wrap: nowrap;
  }
}