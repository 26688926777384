@use "styles/modules/colors.module";

.openFileDialogBtn {
  right: 6px;
  top: 0;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: colors.$storychest-color;

  > span {
    font-size: .9em;
    color: white;
  }
}