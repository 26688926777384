@use "modules/colors.module";

.dropdownMenuSelect {
  width: 240px;
  height: 270px;
  overflow-y: scroll;

  > header {
    width: 100%;
    display: flex;
    height: 50px;
    background-color: colors.$storychest-color;
    color: white;
    align-content: center;
    justify-content: center;
    flex-wrap: wrap;
    font-weight: bold;

    > h2 {
      font-size: 1.5em;
    }
  }

  .dropdown-item {
    text-align: center;
    padding: .35rem 0 .35rem 0;
  }
}
