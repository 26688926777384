@use "modules/colors.module";
/*
@import "~/bootstrap/scss/functions";
@import "~/bootstrap/scss/variables";
@import "~/bootstrap/scss/mixins";

$theme-colors: (
  "primary":    $primary,
  "secondary":  $secondary,
  "success":    $success,
  "info":       $info,
  "warning":    $warning,
  "danger":     $danger,
  "light":      $light,
  "dark":       $dark
);
*/

/* define all colors
$theme-colors: (
  "info": tomato,
  "danger": teal
);
 */

// @import "~bootstrap/scss/variables";

// $primary: #DC3151;
$dark: colors.$storychest-brown;
$secondary: colors.$storychest-gray;

$enable-caret: false !default;

// accordion (https://getbootstrap.com/docs/5.0/components/accordion/)
$accordion-color: colors.$storychest-color;
$accordion-border-color: colors.$storychest-color;
$accordion-bg: transparent;
$accordion-button-active-bg: colors.$storychest-color;
$accordion-button-active-color: white;
$accordion-button-focus-border-color: colors.$storychest-light-color;
$accordion-button-focus-box-shadow: colors.$storychest-light-color;
$accordion-button-padding-y: .5rem;
$accordion-button-padding-x: .75rem;

$font-weight-bolder: bold;

$dropdown-padding-x: 0;
$dropdown-padding-y: 0;
$dropdown-item-padding-x: 1rem;
$dropdown-item-padding-y: .55rem;

.btn-link {
  text-decoration: none !important;
  box-shadow: none !important;
}

@import "~bootstrap/scss/bootstrap";

.dropdown-item {
  white-space: normal;
  border-bottom: 1px solid #ededed;

  &:active, &:focus {
    color: white;
    background-color: colors.$storychest-color;

    span, [class^="icon-"], [class*=" icon-"]{
      color: white !important;
    }

    .dropdown-item-active-text-black {
      color: black;
    }
  }
}
