::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  background-color: #495057;
}

::-webkit-scrollbar-thumb {
  background-color: #DC3151;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.3);
  background-color: white;
}