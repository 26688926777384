@use "modules/colors.module";

.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: colors.$storychest-color;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border .24s ease-in-out;
  cursor: pointer;

  p {
    color: black;
    margin: 0;
    padding: 0;
  }
}

.items {
  display: flex;
  overflow: auto;
}