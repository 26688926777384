@use "modules/colors.module";
@use "modules/media";

.baseModal {
  .modalHeader {
    border-bottom: none;
    height: 70px;
  }

  .modalBody {
    max-height: 600px;
    overflow-y: auto;
    padding: 1rem 2rem 2rem 2rem;
  }

  .modalInputBottomLine {
    background-color: transparent;
    border: 0;
    outline: 0;
    border-bottom: 2px solid black;
    border-radius: 0 !important;
    color: black !important;

    &:hover, &:active, &:focus {
      background-color: transparent !important;
      border-bottom: 2px solid black !important;
      box-shadow: none !important;
    }

    &::placeholder {
      color: black;
    }
  }

  .charactersContainer {
    padding: 0 0 1.5rem 0;
  }
}

@media only screen and (max-width: media.$mobile-breakpoint) {
  .characterItemContainer {
    width: 100% !important;
  }
}