.imageGalleryNavButton {
  &:hover {
    transform: translateY(-50%) scale(1.1);
  }
}

.imageGalleryFullscreenButton {
  top: 0;
  right: 0;
  padding: 8px;
  &:hover {
    transform: scale(1.1);
  }
}

.imageGalleryNavArrowShadow {
  text-shadow: 0.3px 0.3px 1px black;
  font-size: 1.5em;
}