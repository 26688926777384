@use "../modules/media";
@use "../modules/colors.module";

.storyItemFigure {
  width: 400px;
  display: inline-flex;
  flex-direction: column;
  border: 1px solid #c6c6c6;
  box-shadow: colors.$box-shadow;
  margin: 1rem;
  background-color: white;
  -webkit-transition: 0.2s ease-in-out;
  -moz-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;

  figcaption {
    height: 255px;
  }
}

.storyItemContentContainer {
  height: calc(550px - 300px - 85px);
}

.storyItemHeadline {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-align: justify;
  font-size: 1.7em;
  color: colors.$storychest-color;
  margin-bottom: .25rem;
}

.storyItemDescription {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-align: justify;
}

.storyItemBgImageContainer {
  height: 300px;
  background-color: #f9f9f9;
}

.storyItemBgImage {
  // background-image: url(...) needs to be implemented externally
  background-size: cover;
  height: 300px;
  background-position: center center;
  background-repeat: no-repeat;
}

.coverCircleBgImageContainer {
  width: 180px;
  height: 180px;
}

.coverCircleBgImage {
  background-size: cover;
  width: 180px;
  height: 180px;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: lightgrey;
  box-shadow: colors.$box-shadow;
}

.storyItemHeartIcon {
  font-size: 1.2em;
}

.storyItemLikesText {
  font-size: 1.1em;
}

.wrapperIconDots {
  transform: rotate(270deg);

  > .iconDots {
    color: black;
  }
}

@media only screen and (max-width: media.$mobile-breakpoint) {
  .storyItemFigure {
    width: 100%;
    margin: 0 0 1rem 0;

    > .storyItemBgImageContainer {
      width: 100%;

      > .storyItemBgImage {
        width: 100%;
      }
    }
  }
}

.storyMoreDropdownMenu {
  padding: 0;
  margin: 0;
}